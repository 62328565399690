<template>
  <div class="addld">
    <div class="container">
      <div class="conter">
        <div class="formBox">
          <ax-form
            ref="formBox"
            :formBuilder="formBuilder"
            @change="onFormChange"
          >
            <div class="title" slot="title1">&nbsp;基本信息</div></ax-form
          >
          <div class="floor">
            <div style="margin-top: 2%; margin-bottom: 1%">
              <span class="housetype" @click="generateUnits">一键生成户型</span>
              <span class="qingkon" @click="clearAll">清空</span>
            </div>
            <div v-for="(floor, index) in generatedFloors" :key="index">
              <a-collapse>
                <a-collapse-panel>
                  <template #header>
                    <div style="display: flex">
                      <div class="headertitle">{{ floor.floorName }}</div>
                      <div class="itemlenth">
                        <span>合计 {{ floor.total }} 户</span>
                        <span class="addtitle" @click="addNewUnit(floor)"
                          >新增户</span
                        >
                      </div>
                    </div>
                  </template>
                  <div class="collapse-container">
                    <div
                      v-for="(unit, unitIndex) in floor.plate"
                      :key="unitIndex"
                      class="collapse"
                    >
                      {{ unit }}
                      <a-icon
                        class="close-icon"
                        type="close"
                        @click="del(index, unitIndex)"
                      />
                    </div>
                  </div>
                </a-collapse-panel>
              </a-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="btn">
        <a-button @click="closeDialog">取消</a-button>
        <a-button type="primary" @click="submit"> 保存 </a-button>
      </div>
    </div>
  </div>
</template>
  
  <script>
import api from "../../api";

const formList = [
  {
    label: "",
    type: "",
    model: "title1",
    formItem: { colon: false },
    col: { span: 24 },
  },
  {
    label: "起始楼层",
    type: "inputNumber",
    model: "initialFloor",
    options: {
      placeholder: "请输入起始楼层",
    },
    col: { span: 12 },
    rules: [
      {
        required: true,
        pattern: /^-?\d{1,3}$/,
        message: "请输入负数或正整数",
      },
    ],
  },
  {
    label: "最高楼层",
    type: "inputNumber",
    model: "topFloor",
    options: {
      placeholder: "请输入最高楼层",
    },
    col: { span: 12 },
    rules: [
      {
        required: true,
        pattern: /^-?\d{1,3}$/,
        message: "请输入正整数",
      },
    ],
  },
  {
    label: "每层户数",
    type: "inputNumber",
    model: "perFloor",
    options: {
      placeholder: "请输入每层户数",
    },
    col: { span: 12 },
    rules: [
      {
        required: true,
        pattern: /^-?\d{1,3}$/,
        message: "请输入正整数",
      },
    ],
  },
  {
    label: "起始户数",
    type: "inputNumber",
    model: "initialPlate",
    options: {
      placeholder: "请输入起始户数",
    },
    col: { span: 12 },
  },
];

export default {
  data() {
    return {
      api,
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 125 },
        { col: { span: 8 } }
      ),
      initial: null,
      top: null,
      per: null,
      Plate: null,
      generatedFloors: [],
    };
  },
  methods: {
    onFormChange(e, type) {
      if (type === "initialFloor") {
        this.initial = e;
      } else if (type === "topFloor") {
        this.top = e;
      } else if (type === "perFloor") {
        this.per = e;
      } else if (type === "initialPlate") {
        this.Plate = e;
      }
    },
    // 生成户型信息
    generateUnits() {
      if (this.initial === null || this.top === null || this.per === null) {
        this.$message.error("请确保所有必填项已填写");
        return;
      }
      const startFloor = parseInt(this.initial, 10);
      const endFloor = parseInt(this.top, 10);
      const perFloor = parseInt(this.per, 10);
      this.generatedFloors = [];
      for (let i = startFloor; i <= endFloor; i++) {
        if (i === 0) continue;
        const units = this.getUnits(i, perFloor);
        this.generatedFloors.push({
          floorName: i + "楼",
          total: perFloor,
          plate: units,
        });
      }
      console.log(this.generatedFloors);
    },
    getUnits(floorNumber, unitCount) {
      const units = [];
      for (let j = 1; j <= unitCount; j++) {
        const room = `${floorNumber}${j < 10 ? "0" + j : j}`;
        units.push(room);
      }
      return units;
    },
    addNewUnit(floor) {
      const floorNumber = parseInt(floor.floorName, 10); // 将楼层名称转换为数字
      if (!isNaN(floorNumber)) {
        let maxUnitNumber = null;

        // 如果floor.plate为空，初始化一个最低值
        if (floor.plate.length === 0) {
          maxUnitNumber = 0;
        } else {
          // 找到当前最大户号
          maxUnitNumber = Math.max(
            ...floor.plate.map((unit) => {
              const unitNumber = parseInt(
                unit.slice(`${floorNumber}`.length),
                10
              );
              return isNaN(unitNumber) ? 0 : unitNumber; // 确保转换的结果是有效的数字
            })
          );
        }

        // 生成新的户型编号
        const newUnitNumber = `${floorNumber}${String(
          maxUnitNumber + 1
        ).padStart(2, "0")}`;
        floor.plate.push(newUnitNumber);
        floor.total++;
      } else {
        console.error("Invalid floor number:", floor.floorName);
      }
    },
    clearAll() {
      this.generatedFloors = [];
    },
    del(floorIndex, unitIndex) {
      this.generatedFloors[floorIndex].plate.splice(unitIndex, 1);
      this.generatedFloors[floorIndex].total--;
    },
    submit() {
      this.$refs.formBox.form.validateFields(async (err, value) => {
        if (err) return;
      });
    },
    closeDialog() {
      this.$emit("close");
    },
  },
};
</script>
  
<style  lang="less" scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
.addld {
  width: 100%;
  height: 100%;
}
.header {
  width: 100%;
  // height: 0.15rem;
  background: rgba(188, 204, 255, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  margin-top: 2%;
}
.top {
  display: flex;
  align-items: center;
  justify-content: center;
}

.conter {
  margin-top: 2%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.footer {
  margin-top: 2%;
  margin-bottom: 5%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}
.btn {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
.btn .ant-btn {
  margin: 0 10px; /* 在按钮的左右分别添加 10px 的间距 */
}
.title {
  border-left: 4px solid #556bff;
  font-weight: 700;
  font-size: 14px;
  color: #2d3138;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.gridname {
  position: relative;
  width: 100%;
  height: 32px;
  border: 1px solid #d9d9d9;
  text-align: left;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  .gridspan {
    position: absolute;
    left: 11px;
    color: #bfbfbf;
    pointer-events: none;
  }
}

.collapse-container {
  display: flex;
  flex-wrap: wrap; /* 让元素自动换行 */
}
.collapse {
  width: 0.4rem;
  height: 0.2rem;
  border-radius: 6px;
  background-color: #f0f0f0;
  margin-right: 8px;
  margin-bottom: 8px;
  display: flex; /* 让内容垂直居中 */
  justify-content: space-around;
  align-items: center;
}

.close-icon {
  margin-left: 5px;
  cursor: pointer;
}
.housetype {
  font-weight: 700;
  font-size: 12px;
  color: #e6a23c;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-right: 2%;
}
.qingkon {
  font-weight: 700;
  font-size: 12px;
  color: #2d3138;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.addtitle {
  font-weight: 400;
  font-size: 0.07rem;
  color: #556bff;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
}
.headertitle {
  font-weight: 700;
  font-size: 0.06rem;
  color: rgb(45, 49, 56);
  display: flex;
  justify-content: center;
  align-items: center;
}
.itemlenth {
  display: flex;
  justify-content: space-between;
  width: 91%;
  margin-left: 5%;
}
</style>