<!-- 楼栋可视化 -->
<template>
  <div style="width: 100%; height: 100%">
    <div style="width: 100%; height: 5%">
      <div class="container">
        <div class="span1" @click="callback">
          <img src="../../../../../../assets/community/rollback.png" alt="" />
          <span>返回上层</span>
        </div>
        <div class="span2">
          <img src="../../../../../../assets/images/house.png" alt="" />
          <span>{{ this.gridPname }}</span
          ><span>/</span>
          <span>{{ this.gridName }}</span>
          <span class="unitName">{{ this.blockCode }}栋</span>
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 2%"></div>
    <div style="width: 100%; height: 93%">
      <div style="width: 100%; height: 100%; display: flex">
        <!-- 左边 -->
        <div style="width: 40%; height: 100%">
          <div style="width: 100%; height: 100%">
            <div class="develop-header">
              <div
                style="
                  display: flex;
                  justify-content: space-around;
                  margin-bottom: 2%;
                "
              >
                <div class="exist">
                  <div class="exit-title">
                    <span class="title">实有户数</span>
                    <span class="text">{{ this.unitCount }}</span>
                  </div>
                  <div>
                    <img
                      src="../../../../../../assets/images/hushu.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="exist">
                  <div class="exit-title">
                    <span class="title"> 实有人口</span>
                    <span class="text">{{ this.actualPopulation }}</span>
                  </div>
                  <div>
                    <img
                      src="../../../../../../assets/images/renkou.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="exist">
                  <div class="exit-title">
                    <span class="title">党员数量</span>
                    <span class="text">{{ this.dyCount }}</span>
                  </div>
                  <div>
                    <img
                      src="../../../../../../assets/images/danghui.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div style="display: flex; justify-content: space-around">
                <div class="people">
                  <div class="exit-title">
                    <span class="title">常住人口</span>
                    <span class="text">{{ this.localResidentCount }}</span>
                  </div>
                  <div>
                    <img
                      src="../../../../../../assets/images/img-2.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="people">
                  <div class="exit-title">
                    <span class="title">流入人口</span>
                    <span class="text">{{ this.flowResidentCount }}</span>
                  </div>
                  <div>
                    <img
                      src="../../../../../../assets/images/img-1.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="develop-total">
              <div class="develop">&nbsp;重点人群</div>
              <!-- 进度条 -->
              <div
                style="
                  height: 90%;
                  width: 100%;

                  padding-top: 2%;
                  padding-bottom: 2%;
                  background-size: 100% 100%;
                  background-repeat: no-repeat;
                "
              >
                <div
                  id="chart-import"
                  style="
                    width: 100%;
                    height: 100%;
                    padding-left: 2%;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                  "
                ></div>
              </div>
            </div>
            <div class="develop-total">
              <div class="develop">&nbsp;特殊人群</div>
              <!-- 柱状图 -->
              <div
                style="
                  height: 90%;
                  width: 100%;
                  padding-top: 2%;
                  padding-bottom: 2%;
                  background-size: 100% 100%;
                  background-repeat: no-repeat;
                "
              >
                <div
                  id="chart-develop"
                  style="
                    width: 100%;
                    height: 90%;
                    padding-left: 2%;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                  "
                ></div>
              </div>
            </div>
            <div class="develop-footer">
              <div class="develop">
                <span>&nbsp;楼栋信息</span>
                <div style="color: #2a5cff" @click="EditBuildinginformation">
                  <a-icon type="edit" />编辑楼栋信息
                </div>
                <el-dialog
                  title="编辑楼栋信息"
                  width="800px"
                  :visible.sync="BuildingVisible"
                  :z-index="12"
                  :destroy-on-close="true"
                  :close-on-click-modal="false"
                >
                  <EditBuilding
                    ref="position"
                    :id="id"
                    @close="BuildingVisible = false"
                  />
                </el-dialog>
              </div>
              <div
                style="
                  height: 90%;
                  width: 100%;
                  padding-top: 2%;
                  padding-bottom: 2%;
                  margin-left: 2%;
                "
              >
                <a-descriptions title="" layout="vertical">
                  <a-descriptions-item label="所属楼栋">
                    <div class="item">{{ this.blockCode || "--" }}栋</div>
                  </a-descriptions-item>
                  <a-descriptions-item label="所属小区">{{
                    this.gridName || "--"
                  }}</a-descriptions-item>
                  <a-descriptions-item label="房屋类型">
                    {{
                      this.houseType === "0"
                        ? "单元楼"
                        : this.houseType === "1"
                        ? "筒子楼"
                        : this.houseType === "2"
                        ? "别墅"
                        : this.houseType === "3"
                        ? "自建楼"
                        : this.houseType === "4"
                        ? "平房"
                        : this.houseType === "5"
                        ? "其他"
                        : "--"
                    }}</a-descriptions-item
                  >

                  <a-descriptions-item label="楼长姓名">
                    {{ this.managerName || "--" }}
                  </a-descriptions-item>
                  <a-descriptions-item label="楼长电话"
                    >{{ this.managerPhone || "--" }}
                  </a-descriptions-item>
                </a-descriptions>
              </div>
            </div>
          </div>
        </div>
        <!-- 右边 -->
        <div class="right-unit">
          <div style="width: 90%; height: 100%">
            <div class="develop">
              <span>&nbsp;单元楼层信息</span>
              <div style="display: flex">
                <div style="color: #2a5cff" @click="addCells">
                  <a-icon type="edit" />添加单元
                </div>
                &nbsp; &nbsp;
                <div style="color: #2a5cff" @click="EditUnitinformation">
                  <a-icon type="edit" />编辑单元信息
                </div>
              </div>
              <el-dialog
                title="添加单元"
                width="800px"
                :visible.sync="addCellsVisible"
                :z-index="12"
                :destroy-on-close="true"
                :close-on-click-modal="false"
              >
                <addCells ref="position" @close="addCellsVisible = false" />
              </el-dialog>
              <el-dialog
                title="编辑单元信息"
                width="800px"
                :visible.sync="UnitVisible"
                :z-index="12"
                :destroy-on-close="true"
                :close-on-click-modal="false"
              >
                <EditUnitInformation
                  ref="position"
                  @close="UnitVisible = false"
                />
              </el-dialog>
            </div>
            <div class="top">
              <div class="top-unit">
                <span>单元：</span>
                <div
                  v-for="unit in units"
                  :key="unit"
                  @click="changeUnitColor(unit)"
                  :style="{
                    color: selectedUnit === unit ? '#2a5cff' : '',
                  }"
                >
                  {{ unit }}单元
                  <a-icon type="close-circle" />
                </div>
              </div>

              <div class="top-building">
                <span>楼层：</span>
                <div
                  v-for="floor in floors"
                  :key="floor"
                  @click="changeFloorColor(floor)"
                  :style="{
                    color: selectedFloor === floor ? '#2a5cff' : '',
                  }"
                >
                  {{ floor }}楼
                </div>
              </div>
            </div>
            <div class="footer">
              <div class="housing">
                <div style="#E6A23C">
                  <a-icon type="info-circle" />共有39个房源
                </div>
                <div
                  style="color: #2a5cff;  cursor: pointer;/"
                  @click="AddHouse"
                >
                  <a-icon type="edit" />添加房屋
                </div>
                <el-dialog
                  title="添加房屋信息"
                  width="1000px"
                  :visible.sync="houseVisible"
                  :z-index="12"
                  :destroy-on-close="true"
                  :close-on-click-modal="false"
                >
                  <AddHouse ref="position" @close="houseVisible = false" />
                </el-dialog>
              </div>
              <div class="house">
                <div class="house-family" v-for="index in 6" :key="index">
                  <div class="home">
                    <div>
                      <a-icon type="home" />
                      <span>2单元</span>
                      <span>2层</span>
                      <span>201</span>
                    </div>
                    <div>
                      <span style="color: #2a5cff">已住</span>
                      <span style="color: #2a5cff">10人</span>
                    </div>
                  </div>
                  <div class="cont">
                    <div>
                      <span>自住</span>
                      <span class="span2">房屋用途</span>
                    </div>
                    <div>
                      <span>322m²</span>
                      <span class="span2">房屋面积</span>
                    </div>
                    <div>
                      <span>6</span>
                      <span class="span2">房间数</span>
                    </div>
                    <div>
                      <span>南北</span>
                      <span class="span2">房屋朝向</span>
                    </div>
                  </div>
                  <div class="foot">
                    <span>居住人员：</span>
                    <span
                      >彭瑞辰，彭瑞辰，彭瑞辰，彭瑞辰，彭瑞辰，彭瑞辰，彭瑞辰，彭瑞辰</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
      
      <script>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import <组件名称> from '<组件路径>';

import * as echarts from "echarts";
import api from "../api";
import addCells from "./components/addCells.vue";
import AddHouse from "../Common/AddHouse.vue";
import EditBuilding from "./components/EditBuilding.vue";
import EditUnitInformation from "./components/EditUnitInformation.vue";
export default {
  components: { EditBuilding, EditUnitInformation, AddHouse, addCells },
  //import引入的组件需要注入到对象中才能使用
  data() {
    //这里存放数据
    return {
      api,
      chartimport: null,
      chartdevelop: null,
      id: this.$route.query.id,
      gridId: "",
      gridName: "",
      gridPname: "",
      blockCode: "",
      houseType: "",
      managerName: "",
      managerPhone: "",
      unitName: "",
      dyCount: "",
      unitCount: "",
      actualPopulation: "",
      localResidentCount: "",
      flowResidentCount: "",
      data: [],
      unitNames: [], // 存储所有单元的名称
      peopleData: [],
      firstFourData: [],
      lastThreeData: [],
      aggregateData1: [],
      aggregateData2: [],
      houseVisible: false,
      BuildingVisible: false,
      addCellsVisible: false,
      UnitVisible: false,
      units: [1, 2, 3, 4, 5, 6],
      floors: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ],
      selectedUnit: null,
      selectedFloor: null,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    changeUnitColor(unit) {
      this.selectedUnit = unit;
      // 不重置楼层的选择
    },
    changeFloorColor(floor) {
      this.selectedFloor = floor;
      // 不重置单元的选择
    },
    EditBuildinginformation() {
      this.BuildingVisible = true;
    },
    addCells() {
      this.addCellsVisible = true;
    },
    EditUnitinformation() {
      this.UnitVisible = true;
    },
    AddHouse() {
      this.houseVisible = true;
    },

    async getByIdlist() {
      try {
        const res = await api.getById2(this.id);
        this.gridName = res.data.gridName;
        this.gridPname = res.data.gridPname;
        this.blockCode = res.data.blockCode;
        this.houseType = res.data.houseType;
        this.managerName = res.data.managerName;
        this.managerPhone = res.data.managerPhone;
        this.gridId = res.data.gridId;
        // this.getSpecialGroupCounts();
        // this.getKeyPopulationCounts();
        // this.getResidentInfoCounts();
      } catch (err) {
        console.log(error);
      }
    },

    //获取人群类别数量
    async getResidentInfoCounts() {
      if (!this.unitName) {
        this.unitName = this.unitNames[0];
      }
      const obj = {
        gridId: this.gridId,
        buildingId: this.id,
        unitName: this.unitName,
      };
      try {
        const res = await api.InfoCounts(obj);
        // console.log(res);
        if (res && res.data) {
          this.flowResidentCount = res.data.flowResidentCount || 0; //流入人口
          this.dyCount = res.data.dyCount || 0; //党员数量
          this.unitCount = res.data.unitCount || 0; //实有户数
          this.actualPopulation = res.data.actualPopulation || 0; //实有人口
          this.localResidentCount = res.data.localResidentCount || 0; //常住人口
        }
      } catch (error) {
        console.error(error); // 如有错误，打印错误信息
      }
    },
    //获取特殊人群数量
    async getSpecialGroupCounts() {
      if (!this.unitName) {
        this.unitName = this.unitNames[0];
      }

      const obj = {
        gridId: this.gridId,
        buildingId: this.id,
        unitName: this.unitName,
      };
      try {
        const res = await api.GroupCounts(obj);
        // console.log(res);
        if (res && res.data) {
          this.peopleData = [
            res.data.womanCount || 0,
            res.data.childrenCount || 0,
            res.data.elderlyCount || 0,
            res.data.veteranCount || 0,
          ];
          this.getdevelop();
        } else {
          this.peopleData = [0, 0, 0, 0]; // 将数据置为0
          this.getdevelop();
        }
      } catch (error) {
        console.error(error); // 如有错误，打印错误信息
      }
    },
    //获取重点人群数量
    async getKeyPopulationCounts() {
      if (!this.unitName) {
        this.unitName = this.unitNames[0];
      }
      const obj = {
        gridId: this.gridId,
        buildingId: this.id,
        unitName: this.unitName,
      };
      try {
        const res = await api.PopulationCounts(obj);
        // console.log(res);
        if (res && res.data) {
          const values = Object.values(res.data); // 获取 res.data 中的所有值
          const max = Math.max(...values); // 找出最大值
          this.aggregateData1 = Array(4).fill(max / 0.8); // 将 max / 0.8 填充到长度为 4 的数组中
          this.aggregateData2 = Array(3).fill(max / 0.8); // 将 max / 0.8 填充到长度为3 的数组中
          this.firstFourData = [
            res.data.psychopathCount || 0,
            res.data.rectificationCount || 0,
            res.data.cultCount || 0,
            res.data.petitionCount || 0,
          ];
          this.lastThreeData = [
            res.data.emancipistCount || 0,
            res.data.drugCount || 0,
            res.data.minorsCount || 0,
          ];
          this.getprogress();
        } else {
          this.firstFourData = [0, 0, 0, 0]; // 将数据置为0
          this.lastThreeData = [0, 0, 0]; // 将数据置为0
          this.getprogress();
        }
      } catch (error) {
        console.error(error); // 如有错误，打印错误信息
      }
    },

    //进度条
    getprogress() {
      this.chartimport = echarts.init(document.getElementById("chart-import"));
      const option = {
        grid: [
          {
            //左侧的柱状图grid
            width: "46%",
            left: "1%",
            top: "12%",
            right: "0",
            bottom: "0",
          },
          {
            //右侧的柱状图grid
            width: "46%",
            left: "51%",
            top: "10",
            right: "0",
            bottom: "0",
          },
        ],
        xAxis: [
          {
            //左侧柱状图的X轴
            gridIndex: 0, //x 轴所在的 grid 的索引
            show: false,
          },
          {
            //右侧柱状图的X轴
            gridIndex: 1, //x 轴所在的 grid 的索引
            show: false,
          },
        ],
        yAxis: [
          {
            //左侧柱状图的Y轴
            gridIndex: 0, //y轴所在的 grid 的索引
            splitLine: "none",
            axisTick: "none",
            axisLine: "none",
            axisLabel: {
              verticalAlign: "bottom",
              align: "left",
              padding: [0, 0, 8, 10],
              textStyle: {
                color: "#7D8DA6",
                fontSize: "14",
              },
            },
            data: [
              "精神病患者",
              "社区矫正人员",
              "涉邪人员",
              "重点维稳信访人员",
            ],
            inverse: true,
          },
          {
            //左侧柱状图的Y轴
            gridIndex: 0, //y轴所在的 grid 的索引
            splitLine: "none",
            axisTick: "none",
            axisLine: "none",
            data: this.firstFourData,
            inverse: true,
            axisLabel: {
              show: true,
              verticalAlign: "bottom",
              align: "right",
              padding: [0, 20, 5, 0],
              textStyle: {
                color: "#7D8DA6",
                fontSize: "16",
              },
              formatter: function (value) {
                return "{x|" + value + "}";
              },
              rich: {
                x: {
                  color: "#2D3138 ",
                  fontSize: 16,
                },
              },
            },
          },
          {
            //左侧柱状图的Y轴
            gridIndex: 0, //y轴所在的 grid 的索引
            splitLine: "none",
            axisTick: "none",
            axisLine: "none",
            data: [],
          },

          {
            //右侧柱状图的Y轴
            gridIndex: 1, //y轴所在的 grid 的索引
            splitLine: "none",
            axisTick: "none",
            axisLine: "none",
            axisLabel: {
              verticalAlign: "bottom",
              align: "left",
              padding: [0, 0, 8, 10],
              textStyle: {
                color: "#7D8DA6",
                fontSize: "14",
              },
            },
            data: ["刑满释放人员", "吸毒人员", "不良行为青山年"],
            inverse: true,
          },
          {
            //右侧柱状图的Y轴
            gridIndex: 1, //y轴所在的 grid 的索引
            splitLine: "none",
            axisTick: "none",
            axisLine: "none",
            data: this.lastThreeData,
            inverse: true,
            axisLabel: {
              show: true,
              verticalAlign: "bottom",
              align: "right",
              padding: [0, 20, 5, 0],
              textStyle: {
                color: "#fff",
                fontSize: "14",
              },
              formatter: function (value) {
                return "{x|" + value + "}";
              },
              rich: {
                x: {
                  color: "#2D3138 ",
                  fontSize: 16,
                },
              },
            },
          },
          {
            //右侧柱状图的Y轴
            gridIndex: 1, //y轴所在的 grid 的索引
            splitLine: "none",
            axisTick: "none",
            axisLine: "none",
            data: [],
          },
        ],
        series: [
          {
            name: "值",
            type: "bar",
            xAxisIndex: 0, //使用的 x 轴的 index，在单个图表实例中存在多个 x 轴的时候有用。
            yAxisIndex: 0, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用。
            data: this.firstFourData,
            barWidth: 5,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: "#556BFF ", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#556BFF ", // 100% 处的颜色
                    },
                  ],
                  false
                ),
                barBorderRadius: 6,
              },
            },
            z: 2,
          },
          {
            name: "外框",
            type: "bar",
            xAxisIndex: 0, //使用的 x 轴的 index，在单个图表实例中存在多个 x 轴的时候有用。
            yAxisIndex: 2, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用。
            barGap: "-100%",
            data: this.aggregateData1,
            barWidth: 5,
            itemStyle: {
              normal: {
                color: "#edf0f5",
                barBorderRadius: 6,
              },
            },
            z: 0,
          },
          {
            name: "值",
            type: "bar",
            xAxisIndex: 1, //使用的 x 轴的 index，在单个图表实例中存在多个 x 轴的时候有用。
            yAxisIndex: 3, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用。
            data: this.lastThreeData,
            barWidth: 5,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: "#556BFF ", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#556BFF ", // 100% 处的颜色
                    },
                  ],
                  false
                ),
                barBorderRadius: 6,
              },
            },
            z: 2,
          },
          {
            name: "外框",
            type: "bar",
            xAxisIndex: 1, //使用的 x 轴的 index，在单个图表实例中存在多个 x 轴的时候有用。
            yAxisIndex: 5, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用。
            barGap: "-100%",
            data: this.aggregateData2,
            barWidth: 5,
            itemStyle: {
              normal: {
                color: "#edf0f5",
                barBorderRadius: 6,
              },
            },
            z: 0,
          },
        ],
      };
      this.chartimport.setOption(option);
    },
    //柱状图
    getdevelop() {
      this.chartdevelop = echarts.init(
        document.getElementById("chart-develop")
      );
      const option = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.6)",
          borderColor: "rgba(147, 235, 248, 0)",
          textStyle: {
            color: "#FFF",
          },
        },
        grid: {
          left: "6%",
          top: "5%",
          right: "5%",
          bottom: "20%",
        },
        legend: {
          top: "4%",
          left: "75%",
          itemWidth: 13,
          itemHeight: 13,
          itemStyle: {
            color: "#7d8da6",
          },
          icon: "rect",
          padding: 0,
          textStyle: {
            color: "#c0c3cd",
            fontSize: 13,
            padding: [2, 0, 0, 0],
          },
        },
        xAxis: {
          data: ["留守妇女", "留守儿童", "孤寡老人", "退役军人"],
          axisLine: {
            show: true, //隐藏X轴轴线
            lineStyle: {
              color: "#A5B4CB",
              width: 2,
            },
          },
          axisTick: {
            show: false, //隐藏X轴刻度
            alignWithLabel: true,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#7D8DA6", //X轴文字颜色
              fontSize: 12,
            },
            interval: 0,
            formatter: function (value) {
              var ret = ""; //拼接加\n返回的类目项
              var maxLength = 4; //每项显示文字个数
              var valLength = value.length; //X轴类目项的文字个数
              var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
              if (rowN > 1) {
                //如果类目项的文字大于5,
                for (var i = 0; i < rowN; i++) {
                  var temp = ""; //每次截取的字符串
                  var start = i * maxLength; //开始截取的位置
                  var end = start + maxLength; //结束截取的位置
                  //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                  temp = value.substring(start, end) + "\n";
                  ret += temp; //凭借最终的字符串
                }
                return ret;
              } else {
                return value;
              }
            },
          },
        },
        yAxis: [
          {
            type: "value",
            name: "",
            nameTextStyle: {
              color: "#7D8DA6",
              fontSize: 12,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#A5B4CB",
                type: "dashed", // dotted 虚线
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false, //隐藏y轴轴线
              lineStyle: {
                color: "#7D8DA6",
                width: 1,
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#7D8DA6", //y轴文字颜色
                fontSize: 12,
              },
            },
          },
          {
            type: "value",
            name: "",
            nameTextStyle: {
              color: "#7D8DA6",
              fontSize: 12,
            },
            splitLine: {
              show: false,
              lineStyle: {
                width: 1,
                color: "#7D8DA6",
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false, //隐藏X轴轴线
              // lineStyle: {
              //   color: "#163a5f",
              //   width: 2,
              // },
            },
            axisLabel: {
              show: false,
              textStyle: {
                color: "#7D8DA6",
                fontSize: 12,
              },
            },
          },
        ],
        series: [
          {
            name: "",
            type: "bar",
            barWidth: 10,
            itemStyle: {
              color: {
                type: "linear",
                x: 0, //右
                y: 0, //下
                x2: 0, //左
                y2: 1, //上
                colorStops: [
                  {
                    offset: 0.1,
                    color: "#FEB464 ", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#FEB464 ", // 100% 处的颜色
                  },
                ],
              },
              barBorderRadius: [20, 20, 20, 20],
            },
            label: {
              show: false,
              position: "top",
              distance: 0,
              // color: "#1ACDDC",
              // formatter: "{c}" + "次",
            },
            data: this.peopleData,
          },
          {
            // name: '背景',
            type: "bar",
            barWidth: 10,
            xAxisIndex: 0,
            yAxisIndex: 1,
            barGap: "-110%",
            data: [500, 500, 500, 500], //背景阴影长度
            itemStyle: {
              normal: {
                color: "rgba(255,255,255,0.039)",
                barBorderRadius: [20, 20, 20, 20],
              },
            },
            tooltip: {
              show: false,
            },
            zlevel: 9,
          },
        ],
      };

      this.chartdevelop.setOption(option);
    },
    //返回
    callback() {
      this.$router.push({
        path: "/views/communityGovernance/housingList/buildingList/Management",
      });
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {
    setTimeout(() => {
      this.getprogress(); //进度条
      this.getdevelop(); //柱状图
      // this.getKeyPopulationCounts();
      // this.getSpecialGroupCounts();
      // this.getResidentInfoCounts();
    });
    this.$nextTick(() => {
      this.getByIdlist();
    });
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
      <style lang='less' scoped>
.container {
  width: 100%;
  // height: 0.15rem;
  background: rgba(188, 204, 255, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer; /* 添加指针样式，表示可点击 */
}
.span1 {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 1%;
  img {
    margin-right: 0.02rem;
  }
}
.span2 {
  display: flex;
  align-items: center;
  padding-right: 1.5%;
  img {
    margin-left: 0.5%;
  }
  span {
    margin-left: 1%;
    margin-right: 1%;
    font-weight: 700;
    font-size: 0.1rem;
    color: #2d3138;
    line-height: 18px;
    text-align: left;
    white-space: nowrap;
  }
  .unitName {
    color: #556bff;
    margin-left: 0.5%;
    font-weight: 700;
    font-size: 0.1rem;
    line-height: 18px;
    text-align: left;
  }
}
.develop-header {
  width: 100%;
  height: 30%;
}
.develop-total {
  width: 100%;
  height: 24%;
}
.develop-footer {
  width: 100%;
  height: 18%;
}
.develop {
  border-left: 4px solid #556bff;
  font-size: 0.09rem;
  font-weight: bold;
  color: black;
  line-height: 100%;
  margin-bottom: 1%;
  display: flex;
  justify-content: space-between;
  cursor: pointer; /* 添加指针样式，表示可点击 */
}
.heaved {
  width: 0.12rem;
  height: 0.09rem;
  margin-right: 10px;
  background: #faecd8;
  border-top: 2px solid #e6a23c;
}
.leaved {
  width: 0.12rem;
  height: 0.09rem;
  margin-right: 10px;
  background: #dce5ff;
  border-top: 2px solid #556bff;
}
.exist {
  width: 1.02rem;
  height: 0.5rem;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(53, 65, 97, 0.15);
  border-radius: 10px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.people {
  width: 1.59rem;
  height: 0.5rem;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(53, 65, 97, 0.15);
  border-radius: 10px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.text {
  font-weight: 700;
  font-size: 22px;
  color: #2d3138;
  line-height: 28px;
  text-align: left;
}
.exit-title {
  display: flex;
  flex-direction: column;
}
.title {
  font-weight: 400;
  font-size: 14px;
  color: #7d8da6;
  line-height: 22px;
  text-align: left;
}
.message {
  width: 33%;
  height: 18%;
  margin-bottom: 1%;
  display: flex;
  flex-direction: column;
}
.message-title {
  font-weight: 400;
  font-size: 14px;
  color: #7d8da6;
  line-height: 18px;
  text-align: left;
}
.message-tt {
  font-weight: 400;
  font-size: 14px;
  color: #2d3138;
  line-height: 18px;
  text-align: left;
  margin-top: 10px;
}
.footer-message {
  display: flex;
  // justify-content: space-around;
}
.footer-footer {
  display: flex;
  // justify-content: space-around;
}
/deep/.ant-descriptions-item-content {
  display: table-cell;
  font-weight: 400;
  font-size: 14px;
  color: #2d3138;
  line-height: 18px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
/deep/.ant-descriptions-item-label {
  font-weight: 400;
  font-size: 14px;
  color: #7d8da6;
  line-height: 18px;
  text-align: left;
}
.collapse-container {
  display: flex;
  flex-wrap: wrap; /* 让元素自动换行 */
}
.levedheave {
  width: 0.4rem;
  cursor: pointer;
  height: 0.2rem;
  background-color: #f0f0f0;
  margin-right: 10px; /* 可以根据需要调整元素之间的间距 */
  margin-bottom: 10px; /* 可以根据需要调整元素之间的间距 */
  display: flex; /* 让内容垂直居中 */
  justify-content: space-around;
  align-items: center; /* 让内容垂直居中 */
  background: #faecd8;
  border-top: 2px solid #e6a23c;
}
.deserted {
  width: 0.4rem;
  cursor: pointer;
  height: 0.2rem;
  background-color: #f0f0f0;
  margin-right: 10px; /* 可以根据需要调整元素之间的间距 */
  margin-bottom: 10px; /* 可以根据需要调整元素之间的间距 */
  display: flex; /* 让内容垂直居中 */
  justify-content: space-around;
  align-items: center; /* 让内容垂直居中 */
  background: #dce5ff;
  border-top: 2px solid #556bff;
}
.close-icon {
  margin-left: 5px; /* 调整图标与文本之间的间距 */
  cursor: pointer; /* 添加指针样式，表示可点击 */
}
.storey {
  font-weight: 700;
  font-size: 0.07rem;
  color: rgb(45, 49, 56);
  display: flex;
  justify-content: center;
  align-items: center;
}
.household {
  display: flex;
  justify-content: space-between;
  margin-left: 5%;
}
/deep/.el-dialog {
  border-radius: 20px !important;
}
.right-unit {
  width: 60%;
  height: 100%;
  display: flex;
  overflow-y: auto;
  justify-content: flex-end;
  .top {
    width: 100%;
    .top-unit {
      display: flex;
      margin: 2%;
      align-items: center;
      span {
        font-weight: bold;
        font-size: 13px;
        color: #2d3138;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        white-space: nowrap;
      }
      div {
        background: #f2f5ff;
        border-radius: 4px;
        margin-left: 1%;
        margin-right: 1%;
        width: 7%;
        height: 2%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 13px;
        color: #2d3138;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        white-space: nowrap;
        padding: 0.5%; /* 调整内边距 */
        cursor: pointer; /* 添加指针样式，表示可点击 */
      }
    }
    .top-building {
      display: flex;
      flex-wrap: wrap; /* 允许多行排列 */
      margin: 2%;
      align-items: center;
    }

    .top-building span {
      font-weight: bold;
      font-size: 13px;
      color: #2d3138;
      line-height: 16px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      white-space: nowrap;
    }

    .top-building div {
      background: #f2f5ff;
      border-radius: 4px;
      margin: 1%; /* 调整间距 */
      padding: 0.5%; /* 调整内边距 */
      width: 7%;
      height: 2%;
      font-weight: bold;
      font-size: 13px;
      color: #2d3138;
      line-height: 16px;
      text-align: center; /* 更改为居中对齐 */
      font-style: normal;
      text-transform: none;
      white-space: nowrap;
      cursor: pointer; /* 添加指针样式，表示可点击 */
    }
  }
  .footer {
    width: 100%;
    .housing {
      font-size: 0.09rem;
      font-weight: bold;
      color: #e6a23c;
      line-height: 17px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      display: flex;
      justify-content: space-between;
    }
    .house {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .house-family {
        width: 45%; /* 调整每个div的宽度，使其能够容纳更多个div */
        height: 200px;
        background: #ffffff;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        margin: 1%; /* 调整间距 */
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
.home {
  width: 80%;
  height: 10%;
  font-weight: bold;
  font-size: 16px;
  color: #2d3138;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  display: flex;
  margin-top: 4%;
  justify-content: space-between;
  div {
    white-space: nowrap;
    i {
      margin-left: 8%;
      margin-right: 8%;
      color: #6bc2fc;
    }
    span {
      margin-left: 8%;
      margin-right: 8%;
    }
  }
}
.cont {
  margin-top: 4%;
  width: 80%;
  height: 30%;
  display: flex;
  font-weight: 400;
  font-size: 16px;
  color: #2d3138;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  display: flex;
  margin-top: 4%;
  justify-content: space-between;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
    span {
      font-weight: 400;
      font-size: 13px;
      color: #2d3138;
      line-height: 17px;
      text-align: center;
      font-style: normal;
      text-transform: none;
    }
    .span2 {
      margin-top: 15%;
      margin-bottom: 15%;
      font-weight: 400;
      font-size: 13px;
      color: #7d8da6;
      line-height: 17px;
      text-align: center;
      font-style: normal;
      text-transform: none;
    }
  }
}
.foot {
  margin-top: 2%;
  width: 90%;
  height: 30%;
  padding: 2%;
  background: #f2f5ff;
  border-radius: 10px 10px 10px 10px;
  span {
    font-weight: 400;
    font-size: 13px;
    color: #7d8da6;
    line-height: 17px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
}
</style>